const type = [
  {
    id: 2,
    name: "TITLE DETAIL",
    menu: "Title",
    header: "Title Dropdown Detail",
    updated: "UpdateTitleChoice",
  },
  {
    id: 21,
    name: "Gender DETAIL",
    menu: "Gender",
    header: "Gender Dropdown Detail",
    updated: "UpdateGenderChoice",
  },
];

export default type;
